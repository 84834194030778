<!--
  span-method可以指定合并行或列的算法
  该方法参数为四个对象
  · row：当前行
  · column：当前列
  · rowIndex：当前行索引
  · columnIndex：当前列索引
  该函数可以返回一个包含两个元素的数组，第一个元素代表rowspan，第二个元素代表colspan：[1,2]
  也可以返回一个键名为rowspan和colspan的对象: {rowspan:1,colspan:2}

  rowspan = 0  隐藏该行
  rowspan = 1  显示该行
  rowspan > 1  涉及合并的行数或者说合并rowspan-1的行数，需要注意的一点，他是从该行往下合并
               比如rowspan为3，则从这一行开始算，接下来三行会进行合并
-->
<template>
    <div>
        <LiefengContent>
            <template v-slot:title>{{ "商品管理" }}</template>
            <template v-slot:toolsbarRight></template>
            <template v-slot:contentArea>
                <div id="newMap"></div>
            </template>
        </LiefengContent>
        <!--弹窗-->
        <!--
           title:弹窗标题
           value:是否显示弹窗，false为不弹，true弹出，默认false
           inputFn:触发关闭按钮回调函数
       -->
    </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengCascaderCity from "@/components/LiefengCascaderCity"
import videojs from "video.js"
import "videojs-contrib-hls"
export default {
    data() {
        return {
            myVideo: null,
            videoUrl: "http://1258344707.vod2.myqcloud.com/1b87576bvodcq1258344707/48b6f7fb5576678021949095874/playlist.f3.m3u8",
            search: {
                cascaderIdList: [],
            },
        }
    },
    mounted() {
        const meta = document.createElement("meta")
        meta.content = "no-referrer"
        meta.name = "referrer"
        document.getElementsByTagName("head")[0].appendChild(meta)
        // this.$meta().addMeta({
        //     name:'referrer',
        //     content:'no-referrer'
        // })
        setTimeout(() => {
            this.initMap()
        }, 500)
    },
    destroyed() {
        var metaTags = document.getElementsByTagName("meta")

        // 遍历每个meta标签元素
        for (var i = 0; i < metaTags.length; i++) {
            // 判断当前元素的name属性值是否为目标名称（比如"description"）
            if (metaTags[i].getAttribute("name") === "referrer") {
                // 将该元素从DOM结构中删除
                metaTags[i].parentNode.removeChild(metaTags[i])
            }
        }
        console.log("销毁之后触发")
    },
    methods: {
        initMap() {
            // 创建地图实例
            var map
            var zoom = 12
            map = new T.Map("newMap")
            map.centerAndZoom(new T.LngLat(116.40769, 39.89945), zoom)
        },
    },
    components: { LiefengContent, LiefengCascaderCity },
}
</script>

<style lang="less" scoped>
#newMap {
    width: 100%;
    height: 400px;
}
</style>